//@author: travis

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { S25ImageUploadComponent } from "./s25.image.upload.component";
import { FormsModule } from "@angular/forms";
import { ImageCropperModule } from "ngx-image-cropper";

@NgModule({
    declarations: [S25ImageUploadComponent],
    imports: [CommonModule, FormsModule, ImageCropperModule],
    providers: [S25ImageUploadComponent],
    exports: [S25ImageUploadComponent],
})
export class S25ImageUploadModule {
    constructor() {}
}
