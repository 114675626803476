import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit } from "@angular/core";
import { ImageService } from "../../services/image.service";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25ImageService } from "../s25-image/s25.image.service";
import { S25LoadingApi } from "../s25-loading/loading.api";

@TypeManagerDecorator("s25-ng-image-form")
@Component({
    selector: "s25-ng-image-form",
    template: `
        @if (!this.init) {
            <div class="c-margin-top--single"><s25-loading-inline model="{}"></s25-loading-inline></div>
        }
        @if (this.init) {
            <div class="c-margin-top--single">
                <label class="ngInlineBlock c-margin-top--half c-margin-right--double">
                    <span class="ngBold ngInlineBlock c-margin-bottom--quarter">Name</span>
                    <s25-ng-editable-text
                        [max]="40"
                        [allowEmpty]="false"
                        [alwaysEditing]="true"
                        [(val)]="this.model.itemName"
                    ></s25-ng-editable-text>
                </label>
                <label class="ngInlineBlock c-margin-top--half c-margin-right--double">
                    <span class="ngBold ngInlineBlock c-margin-bottom--quarter">Type</span>
                    <s25-generic-dropdown
                        [items]="types"
                        [placeholder]="'Select a type'"
                        [(chosen)]="model.itemType"
                        (chosenChange)="chooseType($event)"
                    ></s25-generic-dropdown>
                </label>
                <div>
                    <label class="ngInlineBlock c-margin-top--half c-margin-right--double">
                        <span class="ngBold ngInlineBlock c-margin-bottom--quarter">Description</span>
                        <s25-ng-editable-textarea
                            [allowEmpty]="true"
                            [alwaysEditing]="true"
                            [(val)]="model.itemDesc"
                        ></s25-ng-editable-textarea>
                    </label>
                </div>
                <s25-ng-image-upload
                    [imageUri]="this.url"
                    [hasCropper]="hasCropper"
                    (uploadedImageChange)="updateImage($event)"
                    [(model)]="imageModel"
                ></s25-ng-image-upload>
                <s25-loading-inline model="{}"></s25-loading-inline>
                @if (errorMsg) {
                    <div class="ngBold ngRed">{{ errorMsg }}</div>
                }
                <button class="aw-button aw-button--primary c-margin-right--quarter" (click)="this.save()">Save</button>
                <button class="aw-button aw-button--outline c-margin-top--single" (click)="this.close('cancel')">
                    Cancel
                </button>
            </div>
        }
    `,
})
export class ImageFormComponent implements OnInit {
    @Input() itemId: number;
    @Input() hasCropper: false;
    @Input() onSave: any; //function
    @Input() cancel: any; //function

    model: any;
    imageModel: any = {};
    url: string;
    init = false;
    errorMsg: string;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {}

    types = [
        {
            itemId: 1,
            itemName: "Photograph",
        },
        {
            itemId: 2,
            itemName: "Diagram",
        },
    ];

    ngOnInit(): void {
        this.elementRef.nativeElement.angBridge = this;

        if (this.itemId) {
            S25LoadingApi.init(this.elementRef.nativeElement);
            this.cd.detectChanges();
            S25Util.all({
                data: ImageService.getImage(this.itemId),
                imageUrl: S25ImageService.getUrl(this.itemId, 400),
            }).then((resp) => {
                let data = resp.data;
                this.url = resp.imageUrl;
                this.model = {
                    itemId: data.image_id,
                    itemName: data.image_name,
                    itemDesc: data.image_desc,
                    itemType: {
                        itemId: data.image_type,
                        itemName: data.image_type_name,
                    },
                    fileName: data.file_name,
                    imageData: data.image_data,
                };
                this.init = true;
                S25LoadingApi.destroy(this.elementRef.nativeElement);
                this.cd.detectChanges();
            });
        } else {
            this.model = {
                itemId: null,
                itemName: "",
                itemDesc: "",
                itemType: {
                    itemId: 1,
                },
                fileName: "",
                imageData: null,
            };
            this.init = true;
            this.cd.detectChanges();
        }
    }

    updateImage(base64ImgStr: string) {
        this.model.fileName = this.imageModel.getImageFileName();
        this.model.imageData = base64ImgStr;
    }

    chooseType(data: any) {}

    save() {
        S25LoadingApi.init(this.elementRef.nativeElement);
        if (this.validate()) {
            return ImageService.setImage(
                this.model.itemId,
                this.model.itemName,
                this.model.itemDesc,
                this.model.fileName,
                this.model.imageData,
                this.model.itemType.itemId,
            ).then(
                (resp) => {
                    this.close("save");
                },
                (err) => {
                    S25LoadingApi.destroy(this.elementRef.nativeElement);
                    S25Util.showError(err);
                },
            );
        } else {
            S25LoadingApi.destroy(this.elementRef.nativeElement);
        }
    }

    close(type: string) {
        if (type === "save") {
            S25LoadingApi.destroy(this.elementRef.nativeElement);
            this.onSave && this.onSave();
        } else if (type === "cancel") {
            S25LoadingApi.destroy(this.elementRef.nativeElement);
            this.cancel && this.cancel();
        }
    }

    validate() {
        this.errorMsg = "";
        if (!this.model.itemName) {
            this.errorMsg = "Please enter an image name";
        } else if (!this.model.imageData) {
            this.errorMsg = "Please upload a valid image";
        }
        return !this.errorMsg;
    }
}
