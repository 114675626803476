import { Component, Input } from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25ImageService } from "./s25.image.service";

@TypeManagerDecorator("s25-ng-image-copy-link")
@Component({
    selector: "s25-ng-image-copy-link",
    template: ` <button
        class="aw-button aw-button--outline tooltipButton"
        title="Copy to Clipboard"
        aria-label="copy image url to clipboard"
        (click)="copyToClipboard()"
        (keyup.enter)="copyToClipboard()"
        tabindex="0"
    >
        {{ this.buttonText }}
        <span class="tooltipBefore">Copy to Clipboard</span>
        <span class="tooltipAfter">Copied!</span>
    </button>`,
})
export class S25ImageCopyLinkComponent {
    @Input() itemId: number;
    @Input() itemName: string;
    @Input() buttonText: string = "Copy Link";

    copyToClipboard() {
        S25Util.copyToClipboard(S25ImageService.getUrl(this.itemId, null));
    }
}
