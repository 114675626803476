//@author: devin
import { S25Util } from "../../util/s25-util";
import { ModalService } from "../modal/modal.service";

declare global {
    interface Window {
        angBridge: any;
    }
}

export class ItemUtil {
    public static DetailOverride: any = {
        10: (model: any) => {
            model = S25Util.extend({}, model, { itemTypeId: model.taskType });
            let modalItem = {
                taskType: model.itemTypeId,
                taskId: model.itemId,
                type: "edit",
                eventId: model.eventId,
                taskActionsCell: model,
                listModelBean: model.listModelBean,
            };
            ModalService.modal("create-edit-task", modalItem);
        },
    };

    public static ItemComponentsToSearch: any = {
        10102: { task: { overview: "Outstanding" } },
        10103: { task: { overview: "Flagged" } },
        10104: { task: { overview: "Assigned_All" } },
        1010: { task: { search: true } },
    };

    public static IconStyles: any = {
        1: "icon-event",
        10001: "icon-event",
        10002: "icon-event",
        10003: "icon-event",
        10004: "icon-event",
        11: "icon-event",
        2: "icon-org",
        3: "icon-contact",
        4: "icon-space",
        14: "icon-space",
        6: "icon-resource",
        16: "icon-resource",
    };
}
