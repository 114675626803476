//@author: devin

import { NgModule } from "@angular/core";
import { S25InfiniteScrollDirective } from "./s25.infinite.scroll.directive";

@NgModule({
    declarations: [S25InfiniteScrollDirective],
    imports: [],
    exports: [S25InfiniteScrollDirective],
    providers: [S25InfiniteScrollDirective],
})
export class S25InfiniteScrollModule {}
