//@author: devin
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    NgZone,
    Renderer2,
    ViewEncapsulation,
} from "@angular/core";
import { S25ItemComponent } from "../s25.item.component";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { ModalService } from "../../modal/modal.service";
import { S25Const } from "../../../util/s25-const";

@TypeManagerDecorator("s25-item-generic")
@Component({
    selector: "s25-item-generic",
    template: `
        @if (init && this.modelBean.itemTypeId === 3) {
            <s25-item-contact
                [modelBean]="modelBean"
                [includeTypeIcon]="includeTypeIcon"
                class="s25-object-contact"
            ></s25-item-contact>
        }
        @if (init && this.modelBean.itemTypeId === 1) {
            <s25-item-event
                [modelBean]="modelBean"
                [includeTypeIcon]="includeTypeIcon"
                class="s25-object-event"
            ></s25-item-event>
        }
        @if (init && this.modelBean.itemTypeId === 2) {
            <s25-item-organization
                [modelBean]="modelBean"
                [includeTypeIcon]="includeTypeIcon"
                class="s25-object-org"
            ></s25-item-organization>
        }
        @if (init && this.modelBean.itemTypeId === 6) {
            <s25-item-resource
                [modelBean]="modelBean"
                [includeTypeIcon]="includeTypeIcon"
                class="s25-object-resource"
            ></s25-item-resource>
        }
        @if (init && this.modelBean.itemTypeId === 4) {
            <s25-item-space
                [modelBean]="modelBean"
                [includeTypeIcon]="includeTypeIcon"
                class="s25-object-space"
            ></s25-item-space>
        }
    `,

    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ItemGenericComponent extends S25ItemComponent {
    constructor(
        elementRef: ElementRef,
        zone: NgZone,
        cd: ChangeDetectorRef,
        componentFactoryResolver: ComponentFactoryResolver,
        renderer: Renderer2,
    ) {
        super(elementRef, zone, cd, componentFactoryResolver, renderer);
    }
}
