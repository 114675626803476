import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { ImageService } from "../../services/image.service";
import { Image } from "../../pojo/Image";
import { jSith } from "../../util/jquery-replacement";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25ImageComponent } from "../s25-image/s25.image.component";
import { S25ImageService } from "../s25-image/s25.image.service";
import { DropDownItem } from "../../pojo/DropDownItem";

export interface ImageSelectorI {
    prevSelected: Image.ImagesI;
    selectedImage: Image.ImagesI;
    onChange: Function;
}

@TypeManagerDecorator("s25-ng-image-selector")
@Component({
    selector: "s25-ng-image-selector",
    template: `
        @if (init) {
            <div>
                <div class="filterContainer">
                    <div class="ngFinePrint c-margin-bottom--quarter">Note: select an image title to preview.</div>
                    <div class="filterParams">
                        <label>
                            Filter By Title:
                            <input
                                (click)="this.blockClose($event)"
                                type="search"
                                [hidden]="false"
                                data-autocomplete="off"
                                data-autocorrect="off"
                                data-autocapitalize="off"
                                data-spellcheck="false"
                                data-role="combobox"
                                data-aria-expanded="true"
                                aria-label="Filter Images"
                                class="c-input"
                                [(ngModel)]="this.searchTerm"
                            />
                        </label>
                        <label>
                            Filter By Type:
                            <s25-generic-dropdown
                                [items]="imageTypes"
                                [(chosen)]="imageTypes[0]"
                                (chosenChange)="searchTypes($event)"
                            ></s25-generic-dropdown>
                        </label>
                    </div>
                </div>
                <div class="imagePreviewList">
                    <div [ngClass]="this.selectedImage ? 'imageList' : 'noImageList'">
                        @if (curResults.length === 0) {
                            <div>No Matches Found</div>
                        }
                        <ul s25-typeahead [action]="this.searchF" [delayMs]="this.typeaheadDelay" aria-live="polite">
                            @for (item of curResults; track item) {
                                <li tabindex="0">
                                    <div
                                        (click)="selectImage(item)"
                                        (keyup.enter)="selectImage(item)"
                                        class="ngCpointer"
                                    >
                                        {{ item.image_name }}
                                    </div>
                                    @if (item.selected) {
                                        <div class="imagePreview">
                                            <a
                                                href="javascript:void(0);"
                                                class="s25modal-close s25modal-close-pos"
                                                (click)="selectImage(item)"
                                                (keyup.enter)="selectImage(item)"
                                                aria-label="Close"
                                            >
                                                <svg class="c-svgIcon" role="img">
                                                    <title>Close</title>
                                                    <use
                                                        xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#close-x"
                                                    ></use>
                                                </svg>
                                            </a>
                                            <h3>Image Preview</h3>
                                            <p>{{ this.selectedImage.image_name }}</p>
                                            <div class="imageInfo">
                                                <a
                                                    (click)="copyLink(item.image_id)"
                                                    aria-label="copy link to clipboard"
                                                    class="c-textButton tooltipButton copyLink"
                                                    tabindex="0"
                                                    title="Copy to Clipboard"
                                                >
                                                    <span class="tooltipBefore">Copy to Clipboard</span>
                                                    <span class="tooltipAfter">Copied!</span>
                                                    Copy Link
                                                </a>
                                                <s25-ng-image [model]="{ imageId: item.image_id }"></s25-ng-image>
                                            </div>
                                        </div>
                                    }
                                </li>
                            }
                            <!--<li tabindex='0' class="ngDropdownItemEl select2-result-label {{(item.isGroup ? 'ngDropdownGroup' : !item.items ? 'ui-select-choices-row' : 'c-nestedDropdown')}}"
                    *ngFor="let item of this.currResults | slice:0:this.itemsLimit">
                    <div class="ngBold ui-select-choices-group-label select2-result-label" *ngIf="item.isGroup">{{item.groupName}}</div>
                  </li>-->
                        </ul>
                    </div>
                </div>
                <!--
            <ul>
              <li *ngFor="let item of images">
                <div (click)="selectImage(item)" class="ngCpointer">{{item.image_name}}</div>
                <div *ngIf="item.selected">
                  <a (click)="copyLink(item.image_id)" aria-label="copy link to clipboard" class="c-textButton tooltipButton copyLink c-floatRight" tabindex="0" title="Copy to Clipboard">
                    <span class="tooltipBefore">Copy to Clipboard</span>
                    <span class="tooltipAfter">Copied!</span>
                    Copy Link
                  </a>
                  <s25-ng-image [model]="{imageId: item.image_id}"></s25-ng-image>
                </div>
              </li>
            </ul>
          -->
            </div>
        }
    `,
})
export class S25ImageSelectorComponent implements OnInit {
    @Input() model: ImageSelectorI;
    @Output() modelChange = new EventEmitter();

    @ViewChild(S25ImageComponent) imageChild: S25ImageComponent;
    images: Image.ImagesI[];
    init = false;

    curResults: Image.ImagesI[];
    searchTerm: string;
    lastSearchTerm: string;
    typeaheadDelay = 250;

    selectedImage: Image.ImagesI;

    filterId: number = 0;
    imageTypes: DropDownItem[] = [
        {
            itemId: 0,
            itemName: "All",
        },
        {
            itemId: 1,
            itemName: "Photograph",
        },
        {
            itemId: 2,
            itemName: "Diagram",
        },
    ];
    currTypeFilter: DropDownItem = this.imageTypes[0];

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        ImageService.getImages().then((resp) => {
            this.images = resp || [];
            this.curResults = S25Util.deepCopy(this.images);
            this.init = true;
            this.cd.detectChanges();
        });
    }

    copyLink(itemId: number) {
        const prevUrl = S25ImageService.getUrl(itemId, null).split("&");

        return ImageService.getImage(itemId).then((resp) => {
            if (prevUrl.length > 0) {
                prevUrl[1] = `filename=${resp.file_name}`;
                const newUrl = prevUrl.slice(0, 2).join("&");

                S25Util.copyToClipboard(newUrl);
            }
        });
    }

    trackByMethod(index: number, item: Image.ImagesI): number {
        return item.image_id;
    }

    selectImage(item: Image.ImagesI) {
        this.model.prevSelected = this.model.selectedImage;
        jSith.forEach(this.curResults, (_, obj) => {
            if (obj.image_id === item.image_id) {
                //found selected item
                if (this.model.prevSelected && this.model.prevSelected.image_id === obj.image_id) {
                    //if selected the same item again
                    obj.selected = false; //collapse it (treat re-select as unselect)
                    this.model.selectedImage = undefined; //nothing is selected now
                    this.model.prevSelected = undefined; //nothing is selected now
                    this.selectedImage = undefined;
                } else {
                    //select item (all others are unselected below)
                    obj.selected = true;
                    this.model.selectedImage = obj; //set selected ref
                    this.selectedImage = obj;
                    // this.imageChild && this.imageChild.init({imageId: item.image_id, title: item.image_name});
                    this.model.onChange && this.model.onChange(obj); //call onChange handler, if any
                }
            } else {
                //unselect all others
                obj.selected = false;
            }
        });

        this.cd.detectChanges();

        this.modelChange && this.modelChange.emit(this.model);
    }

    searchF = () => {
        if (S25Util.isDefined(this.searchTerm) && this.searchTerm !== this.lastSearchTerm) {
            this.currTypeFilter = this.currTypeFilter ? this.currTypeFilter : { itemId: this.filterId };
            let results = S25Util.deepCopy(this.images);

            if (S25Util.parseInt(this.currTypeFilter.itemId) > 0) {
                this.curResults = results.filter(
                    (item: Image.ImagesI) =>
                        item.image_type === this.filterId &&
                        item.image_name &&
                        item.image_name.toLowerCase().includes(this.searchTerm.toLowerCase()),
                );
            } else {
                this.curResults = results.filter(
                    (item: Image.ImagesI) =>
                        item.image_name && item.image_name.toLowerCase().includes(this.searchTerm.toLowerCase()),
                );
            }
            this.currTypeFilter = undefined;
            this.lastSearchTerm = this.searchTerm;
        } else if (this.currTypeFilter && !S25Util.isDefined(this.searchTerm)) {
            let results = S25Util.deepCopy(this.images);
            this.curResults =
                S25Util.parseInt(this.currTypeFilter.itemId) > 0
                    ? results.filter((image: Image.ImagesI) => image.image_type === this.filterId)
                    : results;
            this.currTypeFilter = undefined;
        } else if (
            this.currTypeFilter &&
            S25Util.isDefined(this.searchTerm) &&
            this.searchTerm === this.lastSearchTerm
        ) {
            let results = S25Util.deepCopy(this.images);

            if (S25Util.parseInt(this.currTypeFilter.itemId) > 0) {
                this.curResults = results.filter(
                    (item: Image.ImagesI) =>
                        item.image_type === this.filterId &&
                        item.image_name &&
                        item.image_name.toLowerCase().includes(this.searchTerm.toLowerCase()),
                );
            } else {
                this.curResults = results.filter(
                    (item: Image.ImagesI) =>
                        item.image_name && item.image_name.toLowerCase().includes(this.searchTerm.toLowerCase()),
                );
            }
            this.currTypeFilter = undefined;
        }

        this.cd.detectChanges();
    };

    searchTypes = (type: DropDownItem) => {
        this.currTypeFilter = type;
        this.filterId = +type.itemId;
    };

    blockClose = (event: Event) => {
        // console.log('event', event);
    };
}
