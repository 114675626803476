//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { jSith } from "../../util/jquery-replacement";
import { S25ImageService } from "./s25.image.service";
import { LangService } from "../../services/lang.service";
import { S25LoadingApi } from "../s25-loading/loading.api";
import { ImageService } from "../../services/image.service";
import { LoginService } from "../../services/login.service";

export interface ImageModelI {
    imageId: number;
    title?: string;
    imageUrl?: string;
    imageUrlWidth?: string;
    bootstrap?: any;
    fileType?: string;

    autoSaveUpload?: boolean;
    action?: Function;
    data?: any;
}
@TypeManagerDecorator("s25-ng-image")
@Component({
    selector: "s25-ng-image",
    template: `
        <s25-loading-inline model="{}"></s25-loading-inline>
        @if (isPromiseResolved) {
            <div class="s25-image s25-image-body">
                @if (isError) {
                    <div class="s25-error">{{ lang.image_load_error }}</div>
                }
                @if (!isError && bean != "") {
                    <div>
                        <!--no link if the objectId isn't available-->
                        @if (!!bean.imageId) {
                            <a [href]="bean.imageUrl" target="_blank" rel="noopener" class="s25-image-object">
                                <img
                                    [src]="imageObject.imageUrl"
                                    [alt]="bean.title"
                                    [title]="imageObject.title"
                                    (error)="onError()"
                                    (load)="onLoad()"
                                />
                            </a>
                        }
                        @if (!bean.imageId) {
                            <img
                                [src]="imageObject.imageUrl"
                                [alt]="bean.title"
                                [title]="imageObject.title"
                                (error)="onError()"
                                (load)="onLoad()"
                                class="s25-image-object"
                            />
                        }
                    </div>
                }
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25ImageComponent implements OnInit {
    //S25ImageService_BootstrapDefault {point: 'sm', numCols: 3}
    //model: {imageId: Integer, title: String, bootstrap: {point: String, numCols: Integer}}
    @Input() model: ImageModelI = undefined;

    bean: any;
    lang: any;
    loadedWidth: any;
    isPromiseResolved: boolean = false;
    imageObject: { imageUrl: string; title: string };
    isError: boolean = false;
    isLoggedIn: boolean;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.elementRef.nativeElement.angBridge = this;

        if (this.model) {
            S25LoadingApi.init(this.elementRef.nativeElement);
            jSith.addClass(this.elementRef.nativeElement, "s25-image");

            this.cd.detectChanges();
            this.init(this.model);
        }
    }

    init(model: any) {
        return S25Util.all({
            bean: this.createBean(model.title, model),
            lang: LangService.getLang(),
            loadedWidth: S25ImageService.getLoadedBootstrapWidth(model.imageId, model.bootstrap),
            isLoggedIn: LoginService.getLoggedIn(),
        }).then((response) => {
            this.isPromiseResolved = true;
            this.loadedWidth = response.loadedWidth;
            this.lang = response.lang.div.application;
            this.bean = response.bean;
            this.imageObject = this.createImageObjectModel(this.bean);
            this.isLoggedIn = response.isLoggedIn;

            this.cd.detectChanges();
        });
    }

    createImageObjectModel(bean: any) {
        let url: string;
        if (this.model.imageUrl) url = this.model.imageUrl;
        else if (!url && bean.imageId) bean.imageUrlWidth;
        else S25Util.toBase64URI(bean.data, bean.fileType);

        return {
            imageUrl: url,
            title: bean.title,
        };
    }

    onLoad() {
        if (!this.isLoggedIn) {
            S25LoadingApi.destroy(this.elementRef.nativeElement);
            this.cd.detectChanges();
            return;
        }

        return this.getUrl(this.model.imageId)
            .then((data) => {
                this.imageObject.imageUrl = data;
                this.cd.markForCheck();
                S25LoadingApi.destroy(this.elementRef.nativeElement);
            })
            .catch((error) => {
                S25Util.showError(error);
                S25LoadingApi.destroy(this.elementRef.nativeElement);
            });
    }

    onError() {
        setTimeout(() => {
            S25LoadingApi.destroy(this.elementRef.nativeElement);
            this.isError = true;
            this.cd.detectChanges();
        }, 1);
    }

    createBean(title: any, model: any) {
        return S25Util.all({
            title: title || model.title,
            imageUrlWidth: S25ImageService.getUrlBootstrapWidth(model.imageId, model.bootstrap),
            imageUrl: this.model.imageUrl || S25ImageService.getUrl(model.imageId, null),
        }).then(function (response) {
            S25Util.extend(model, response);
            return model;
        });
    }

    async getUrl(itemId: number) {
        if (this.model.imageUrl) return this.model.imageUrl;
        if (!itemId) return "";

        const prevUrl = this.imageObject.imageUrl.split("&");
        const resp = await ImageService.getImage(itemId);
        let formattedName;
        for (let i = resp.file_name.length - 1; i >= 0; i--) {
            if (resp.file_name[i] === "\\") {
                formattedName = resp.file_name.slice(i + 1);
                break;
            }
        }

        if (prevUrl.length > 0) {
            prevUrl[1] = `filename=${formattedName ?? resp.file_name}`;

            return prevUrl.slice(0, 2).join("&");
        }
    }
}
