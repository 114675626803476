//@author: devin
import { Api } from "../../api/api";

export class InfiniteScrollApi extends Api {
    public static clear(relativeElement: HTMLElement) {
        return Api.callApiFn(relativeElement, "[s25-infinite-scroll]", "clear");
    }

    public static refresh(relativeElement: HTMLElement) {
        return Api.callApiFn(relativeElement, "[s25-infinite-scroll]", "refresh");
    }

    public static reset(relativeElement: HTMLElement) {
        return Api.callApiFn(relativeElement, "[s25-infinite-scroll]", "reset");
    }

    public static forceScrollAction(relativeElement: HTMLElement) {
        return Api.callApiFn(relativeElement, "[s25-infinite-scroll]", "forceScrollAction");
    }

    public static addAllPages(relativeElement: HTMLElement, data: any) {
        return Api.callApiFn(relativeElement, "[s25-infinite-scroll]", "addAllPages", data);
    }
}
