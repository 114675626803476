//@author: devin
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    NgZone,
    Renderer2,
    ViewEncapsulation,
} from "@angular/core";
import { S25ItemComponent } from "../s25.item.component";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-item-resource")
@Component({
    selector: "s25-item-resource",
    template: `
        @if (init) {
            <s25-item
                [modelBean]="modelBean"
                [includeTypeIcon]="includeTypeIcon"
                [popoverTemplate]="resourceTooltip"
            ></s25-item>
        }

        <ng-template #resourceTooltip>
            <div class="qtip-content">
                @if (!modelBean.initPopOver) {
                    <div>Loading...</div>
                }
                @if (modelBean.initPopOver && !modelBean.data?.item) {
                    <div class="resourceBox popoverroot">Could not load resource details</div>
                }
                @if (modelBean.initPopOver && modelBean.data?.item) {
                    <div class="resourceBox popoverroot">
                        <div
                            class="boxHeader"
                            (contextmenu)="contextMenu({ itemTypeId: 6, itemId: modelBean.itemId }, $event)"
                        >
                            <div class="boxHeaderName">{{ modelBean.data.item.name }}</div>
                        </div>
                        <div class="boxBody">
                            @if (modelBean.data.item.categories) {
                                <div>
                                    <div>
                                        <span class="c-dataLabel">Categories: </span>
                                        <span> {{ modelBean.data.item.categories }} </span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        </ng-template>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ItemResourceComponent extends S25ItemComponent {
    constructor(
        elementRef: ElementRef,
        zone: NgZone,
        cd: ChangeDetectorRef,
        componentFactoryResolver: ComponentFactoryResolver,
        renderer: Renderer2,
    ) {
        super(elementRef, zone, cd, componentFactoryResolver, renderer);
    }

    ngOnInit() {
        this.itemTypeId = 6;
        super.ngOnInit();
    }
}
