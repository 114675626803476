import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { S25LoadingInlineComponent } from "./s25.loading.inline.component";
import { S25LoadingInlineStaticComponent } from "./s25.loading.inline.static.component";

@NgModule({
    declarations: [S25LoadingInlineComponent, S25LoadingInlineStaticComponent],
    imports: [CommonModule],
    providers: [S25LoadingInlineComponent, S25LoadingInlineStaticComponent],
    exports: [S25LoadingInlineComponent, S25LoadingInlineStaticComponent],
})
export class S25LoadingInlineModule {
    constructor() {}
}
