import { NgModule } from "@angular/core";
import { S25InfoMessageComponent } from "./s25.info.message.component";
import { CommonModule } from "@angular/common";
import { S25IconModule } from "../s25-icon/s25.icon.module";
import { PopoverModule } from "../s25-popover/popover.module";

@NgModule({
    imports: [CommonModule, S25IconModule, PopoverModule],
    exports: [S25InfoMessageComponent],
    declarations: [S25InfoMessageComponent],
    providers: [S25InfoMessageComponent],
})
export class S25InfoMessageModule {}
