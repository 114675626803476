//@author: devin
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    NgZone,
    Renderer2,
    ViewEncapsulation,
} from "@angular/core";
import { S25ItemComponent } from "../s25.item.component";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-item-event")
@Component({
    selector: "s25-item-event",
    template: `
        @if (init) {
            <s25-item
                [modelBean]="modelBean"
                [includeTypeIcon]="includeTypeIcon"
                [popoverTemplate]="eventTooltip"
                [empty]="empty"
                [inactive]="inactive"
                [ariaLabel]="ariaLabel"
                [contextMenuOnBody]="contextMenuOnBody"
                [dataService]="dataService"
                ><ng-content></ng-content
            ></s25-item>
        }

        <ng-template #eventTooltip>
            <div class="qtip-content">
                @if (!modelBean.initPopOver) {
                    <div>Loading...</div>
                }
                @if (modelBean.initPopOver && !modelBean.data?.item) {
                    <div class="eventBox popoverroot">Could not load event details</div>
                }
                @if (modelBean.initPopOver && modelBean.data?.item) {
                    <div class="eventBox popoverroot">
                        <div
                            class="boxHeader"
                            (contextmenu)="contextMenu({ itemTypeId: 1, itemId: modelBean.itemId }, $event)"
                        >
                            <div class="boxHeaderName">{{ modelBean.data.item.event_name }}</div>
                        </div>
                        <div class="boxBody">
                            @if (modelBean.data.item.rows) {
                                <div>
                                    @for (row of modelBean.data.item.rows.data; track trackByFn($index, row)) {
                                        <div>
                                            <div>
                                                @if (row.type !== 2) {
                                                    <span class="c-dataLabel">{{ row.name }}</span>
                                                }
                                                @if (row.type == 2 && modelBean.data.item.rsrv_start_dt) {
                                                    <span class="c-dataLabel">{{ row.name }}</span>
                                                }
                                            </div>
                                            <div>
                                                @if (row.type == 1) {
                                                    <span>{{ row.value }}</span>
                                                }
                                                @if (row.type == 3) {
                                                    <span [innerHTML]="row.value"></span>
                                                }
                                                @if (row.type == 2 && modelBean.data.item.rsrv_start_dt) {
                                                    <span>
                                                        <span>
                                                            {{
                                                                modelBean.data.item.rsrv_start_dt
                                                                    | dateFormat: modelBean.data.timeFormat
                                                            }}
                                                            @if (
                                                                (modelBean.data.item.rsrv_start_dt
                                                                    | dateFormat: modelBean.data.dateFormat) !==
                                                                (modelBean.data.item.rsrv_end_dt
                                                                    | dateFormat: modelBean.data.dateFormat)
                                                            ) {
                                                                <span class="meridiem angular fulldate">
                                                                    {{
                                                                        " (" +
                                                                            (modelBean.data.item.rsrv_start_dt
                                                                                | dateFormat
                                                                                    : modelBean.data.dateFormat) +
                                                                            ")"
                                                                    }}
                                                                </span>
                                                            }
                                                            <span class="meridiem">{{ " - " }}</span>
                                                        </span>
                                                        <span>
                                                            {{
                                                                modelBean.data.item.rsrv_end_dt
                                                                    | dateFormat: modelBean.data.timeFormat
                                                            }}
                                                            @if (
                                                                (modelBean.data.item.rsrv_start_dt
                                                                    | dateFormat: modelBean.data.dateFormat) !==
                                                                (modelBean.data.item.rsrv_end_dt
                                                                    | dateFormat: modelBean.data.dateFormat)
                                                            ) {
                                                                <span class="meridiem angular fulldate">
                                                                    {{
                                                                        " (" +
                                                                            (modelBean.data.item.rsrv_end_dt
                                                                                | dateFormat
                                                                                    : modelBean.data.dateFormat) +
                                                                            ")"
                                                                    }}
                                                                </span>
                                                            }
                                                        </span>
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                    }
                                    @for (loc of modelBean.data?.item?.spaces?.data; track trackByFn($index, loc)) {
                                        @if (modelBean.data?.item?.spaces) {
                                            <div class="dataItemSpaces">
                                                <span class="c-dataLabel">{{
                                                    ($index === 0 && "Location(s): ") || " "
                                                }}</span>
                                                <div class="ngItemSpace">
                                                    @if (loc.is_hidden != 1) {
                                                        <span
                                                            class="ngInline s25-object s25-object-space summary"
                                                            (click)="
                                                                itemDetail(
                                                                    { itemTypeId: 4, itemId: loc.space_id },
                                                                    $event
                                                                )
                                                            "
                                                            (contextmenu)="
                                                                contextMenu(
                                                                    { itemTypeId: 4, itemId: loc.space_id },
                                                                    $event
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                class="icon-space ngInline s25-object s25-object-space summary"
                                                            ></div>
                                                            <div class="ngInline s25-object s25-object-space summary">
                                                                {{ loc.space_name }}
                                                            </div>
                                                        </span>
                                                    }
                                                    @if (loc.is_hidden == 1) {
                                                        <span class="ngInline">
                                                            <div class="icon-space ngInline"></div>
                                                            <div class="ngInline">{{ loc.space_name }}</div>
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    }
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        </ng-template>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ItemEventComponent extends S25ItemComponent {
    constructor(
        elementRef: ElementRef,
        zone: NgZone,
        cd: ChangeDetectorRef,
        componentFactoryResolver: ComponentFactoryResolver,
        renderer: Renderer2,
    ) {
        super(elementRef, zone, cd, componentFactoryResolver, renderer);
    }

    ngOnInit() {
        this.itemTypeId = 1;
        super.ngOnInit();
    }
}
