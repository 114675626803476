//@author: travis

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { S25ImageSelectorComponent } from "./s25.image.selector.component";
import { FormsModule } from "@angular/forms";
import { S25ImageModule } from "../s25-image/s25.image.module";
import { S25TypeaheadModule } from "../s25-typeahead/s25.typeahead.module";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";

@NgModule({
    declarations: [S25ImageSelectorComponent],
    imports: [CommonModule, FormsModule, S25ImageModule, S25TypeaheadModule, S25DropdownPaginatedModule],
    providers: [S25ImageSelectorComponent],
    exports: [S25ImageSelectorComponent],
})
export class S25ImageSelectorModule {
    constructor() {}
}
