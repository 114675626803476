import { NgModule } from "@angular/core";
import { S25LocationAlwaysShareComponent } from "./s25.location.always.share.component";
import { CommonModule } from "@angular/common";
import { S25ToggleButtonModule } from "../s25-toggle-button/s25.toggle.button.module";

@NgModule({
    imports: [CommonModule, S25ToggleButtonModule],
    exports: [S25LocationAlwaysShareComponent],
    declarations: [S25LocationAlwaysShareComponent],
    providers: [S25LocationAlwaysShareComponent],
})
export class S25LocationAlwaysShareModule {}
