import { Component, Input, OnInit } from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-info-message")
@Component({
    selector: "s25-ng-info-message",
    template: `
        <ng-template #content><ng-content></ng-content></ng-template>
        <ng-template #toggleButton>
            <button class="info-message-toggle--button" tabindex="0" [attr.aria-label]="label" (click)="toggleOpen()">
                <s25-ng-icon [type]="'info'" [label]="label"></s25-ng-icon>
            </button>
        </ng-template>

        @if (!isInline) {
            <div class="ngInlineBlock">
                @if (isPopover) {
                    <s25-popover
                        [modelBean]="{ popoverTemplate: content }"
                        [openTrigger]="'click'"
                        [closeTrigger]="'click'"
                    >
                        <ng-container [ngTemplateOutlet]="toggleButton"></ng-container>
                    </s25-popover>
                }
                @if (!isPopover) {
                    <ng-container [ngTemplateOutlet]="toggleButton"></ng-container>
                }
            </div>
        }

        @if (!isPopover) {
            <div class="eventFormMessage">
                @if (open) {
                    <div class="rose-item--info info-message" tabindex="0">
                        <ng-container [ngTemplateOutlet]="content"></ng-container>
                    </div>
                }
            </div>
        }
    `,
    styles: `
        .info-message-toggle--button {
            border: 0;
            padding: 0;
            background-color: transparent;
            cursor: pointer;
        }

        ::ng-deep .info-message-toggle--button svg {
            transition: all 0.35s;
            transform: rotate(0);
            background-color: transparent;
            color: #2573a7;
            padding-bottom: 2px;
        }

        ::ng-deep .nm-party--on s25-ng-info-message .info-message-toggle--button svg {
            color: #73b0e2 !important;
        }

        .eventFormMessage {
            display: block;
            width: 100%;
            max-width: 100%;
        }
    `,
})
export class S25InfoMessageComponent implements OnInit {
    @Input() id: number;
    @Input() customLabel?: string;
    @Input() isInline?: boolean;
    @Input() open?: boolean = false;
    @Input() isPopover: boolean = false;

    label: string = "";

    ngOnInit() {
        this.label = `Open or Close ${this.customLabel ?? ""} Info Message`;
        if (this.isInline) this.open = true;
    }

    toggleOpen() {
        this.open = !this.open;
    }
}
