//@author: devin
import { Directive, ElementRef, HostListener, Input, NgZone, OnDestroy, OnInit } from "@angular/core";
import { jSith } from "../../util/jquery-replacement";
import { InfiniteScrollUtil } from "./infinite.scroll.util";
import { S25Util } from "../../util/s25-util";

@Directive({
    selector: "[s25-infinite-scroll]",
})
export class S25InfiniteScrollDirective implements OnInit, OnDestroy {
    static DEFAULT_THRESHOLD: number = 80.0;
    static UX_UPDATE_MS: number = 250;

    @Input() onScroll: () => Promise<void>;
    @Input() hasMorePages: () => boolean;
    @Input() topSelector?: string;
    @Input() scrollThreshold?: number;
    @Input() ready?: Promise<any>;
    @Input() minScrollAmount: number = 0; // e.g. 100 would immediately load more data if the scrollable amount is less than 100px

    constructor(
        private elementRef: ElementRef,
        private zone: NgZone,
    ) {
        elementRef.nativeElement.angBridge = this;
    }

    topElement: HTMLElement | Window;
    scrollPosn: number = 0;
    ticking: boolean = false;
    scrollThresholdPx: number = 0;
    scrollablePx: number = 0;
    running: boolean = true;

    clear = () => {
        this.running = false;
    };

    refresh = () => {
        this.setScrollPosn();
        this.setScrollThresholdPx();
        this.running = true;
    };

    setScrollPosn = () => {
        this.scrollPosn = InfiniteScrollUtil.getScrollPosn(this.topElement);
    };

    @HostListener("window:resize")
    onResize() {
        this.setScrollThresholdPx();
        this.infinScrollHandler();
    }

    setScrollThresholdPx = () => {
        this.scrollablePx = InfiniteScrollUtil.invisiblePixelHeight(this.topElement);
        this.scrollThresholdPx = this.scrollablePx * this.scrollThreshold;
    };

    addAllPages = (d: any): any => {
        return this.infinScrollAction(Math.pow(2, 31) - 1).then(() => {
            if (this.topElement && this.hasMorePages()) {
                return this.addAllPages(d);
            } else {
                if (d && d.defer && d.defer.resolve) {
                    d.defer.resolve();
                }
                return;
            }
        });
    };

    forceScrollAction = () => {
        this.refresh();
        return this.infinScrollAction(this.scrollPosn);
    };

    infinScrollAction = (scrollPosn: number) => {
        const needsMore = scrollPosn >= this.scrollThresholdPx || this.minScrollAmount > this.scrollablePx;
        if (this.topElement && this.running && needsMore && this.hasMorePages()) {
            return this.onScroll().then(() => {
                return jSith.timeout(() => {
                    this.setScrollPosn();
                    this.setScrollThresholdPx();
                    return this.infinScrollAction(this.scrollPosn);
                }, S25InfiniteScrollDirective.UX_UPDATE_MS);
            });
        } else {
            return jSith.when();
        }
    };

    infinScrollHandler = () => {
        this.setScrollPosn();
        const needsMore = this.scrollPosn >= this.scrollThresholdPx || this.minScrollAmount > this.scrollablePx;
        if (!this.ticking && needsMore) {
            let scrollPosn = this.scrollPosn;
            window.requestAnimationFrame(() => {
                this.infinScrollAction(scrollPosn).then(() => {
                    this.ticking = false;
                });
            });
            this.ticking = true;
        }
    };

    setTopElement = () => {
        if (document.contains(this.elementRef.nativeElement)) {
            if (this.topSelector === "window") this.topElement = window;
            else if (["document", "body"].includes(this.topSelector)) this.topElement = document.body;
            else if (this.topSelector === "self") this.topElement = this.elementRef.nativeElement;
            else {
                this.topElement =
                    jSith.findSingle(this.elementRef.nativeElement, this.topSelector) ||
                    jSith.findSingle(document.body, this.topSelector);
                if (!this.topElement) {
                    throw new Error("Top Element (" + this.topSelector + ") Not Found.");
                }
            }
        }
    };

    reset = () => {
        this.setTopElement();
        return this.forceScrollAction();
    };

    ngOnInit() {
        this.scrollThreshold =
            S25Util.parseFloat(this.scrollThreshold || S25InfiniteScrollDirective.DEFAULT_THRESHOLD) / 100;
        this.topSelector = this.topSelector || "window";
        this.ready = this.ready || jSith.when();
        this.hasMorePages =
            this.hasMorePages ||
            function () {
                return false;
            };
        this.onScroll =
            this.onScroll ||
            function () {
                return jSith.when();
            };
        this.ready.then(() => {
            this.zone.run(() => {
                setTimeout(() => {
                    if (document.contains(this.elementRef.nativeElement)) {
                        //make sure element still in DOM after timeout
                        this.running = true;
                        this.ticking = false;
                        this.setTopElement();
                        this.setScrollThresholdPx();
                        jSith.on(this.topElement, "scroll", this.infinScrollHandler);
                        this.infinScrollHandler();
                    }
                }, S25InfiniteScrollDirective.UX_UPDATE_MS);
            });
        });
    }

    ngOnDestroy() {
        jSith.off(this.topElement, "scroll", this.infinScrollHandler);
    }
}
