//@author: travis

import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform } from "ngx-image-cropper";
import { jSith } from "../../util/jquery-replacement";
import { TypeManagerDecorator } from "../../main/type.map.service";

export interface ImageUploadModelI {
    getImageData?: () => Promise<string>;
    getImageFileName?: () => string;
    getImageType?: () => string;
    getWidth?: () => number;
    getErrorMsg?: () => string;
}

export interface ImageActionDataI {
    imageName?: string;
    imageDesc?: string;
    imageFileName?: string;
    imageType?: string;
    imageData?: string; //base64 image or imageUri,
    imageId?: number;
    imageTypeId?: number;
}

@TypeManagerDecorator("s25-ng-image-upload")
@Component({
    selector: "s25-ng-image-upload",
    styles: [".overlay{--cropper-overlay-color: blue !important}"],
    template: `
        @if (!hasCropper) {
            <div class="upload-btn-wrapper">
                <input
                    type="file"
                    #file
                    (change)="fileChangeEvent(file.files)"
                    class="ngUnboundedUpload c-margin-bottom--half"
                    value="'Choose a file'"
                />
                <button class="file-btn">Upload</button>
            </div>
            @if (imageUri) {
                <div class="s25-image-body">
                    <span class="imagePreviewLabel">Preview</span>
                    <img [src]="imageUri" alt="Image Upload Preview" />
                </div>
            }
        }

        @if (hasCropper) {
            @if (options.hasRotate || options.hasZoom) {
                <div class="c-margin-bottom--single">
                    @if (options.hasRotate) {
                        <button class="aw-button aw-button--outline" (click)="rotate(-1)">Rotate Left</button>
                        <button class="aw-button aw-button--outline" (click)="rotate(1)">Rotate Right</button>
                    }
                    @if (options.hasZoom) {
                        <button class="aw-button aw-button--outline" (click)="scale(-1)">Zoom Out</button>
                        <button class="aw-button aw-button--outline" (click)="scale(1)">Zoom In</button>
                    }
                </div>
            }
            <div style="--cropper-overlay-color: gray">
                <image-cropper
                    #cropper
                    [imageBase64]="this.imageBase64String"
                    [maintainAspectRatio]="false"
                    [cropperMinHeight]="minHeight"
                    [cropperMaxHeight]="maxHeight"
                    [cropperMinWidth]="minWidth"
                    [cropperMaxWidth]="maxWidth"
                    [canvasRotation]="rotation"
                    (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded($event)"
                    (cropperReady)="cropperReady($event)"
                    (loadImageFailed)="loadImageFailed($event)"
                    [transform]="transform"
                    [output]="'base64'"
                ></image-cropper>
            </div>
            <div class="upload-btn-wrapper c-margin-top--single">
                <input
                    type="file"
                    #file
                    (change)="fileChangeEvent(file.files)"
                    aria-label="'Upload Logo'"
                    class="ngCroppieUpload"
                    value="'Choose a file'"
                />
                <button class="file-btn">Upload</button>
            </div>
        }

        <div class="ngFinePrint c-margin-bottom--half">
            Note: a light gray background is used behind your image to display any transparent text or designs. It is
            not part of the image that will be uploaded.
        </div>
    `,
})
export class S25ImageUploadComponent implements OnInit {
    @Input() model: ImageUploadModelI = {}; //allows access to some image details
    @Input()
    set imageUri(value: string) {
        this._imageUri = value;
        this.onImageUriChange();
    }
    get imageUri(): string {
        return this._imageUri;
    }
    @Input() hasCropper: boolean = false;
    @Input() imgWidth: number = undefined; //not currently used
    @Input() imgHeight: number = undefined; //not currently used
    @Input() minWidth?: number;
    @Input() maxWidth?: number;
    @Input() minHeight?: number;
    @Input() maxHeight?: number;
    @Input() isModal: boolean;

    @Input() options: { hasRotate?: boolean; hasZoom?: boolean } = {};

    @Output() modelChange = new EventEmitter();
    @Output() uploadedImageChange = new EventEmitter<string>();
    @ViewChild(ImageCropperComponent) cropperChild: ImageCropperComponent;

    private _imageUri: any = undefined;
    uploadedFile: File;
    imageBase64String: any; //had trouble sending image to ngx-image-cropper in any other format
    init = true;
    croppedImage: string = "";
    croppedImageWidth: number;
    SUPPORTED_TYPES: string[] = ["png", "jpg", "jpeg", "gif"];
    errorMsg: string;
    transform: ImageTransform = { scale: 1 };
    rotation: number = 0;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        //expose ability for creator to get image data
        this.createModel();
    }

    createModel() {
        this.model = this.model || {};
        this.model.getImageData = () => {
            if (this.imageUri) {
                if (this.hasCropper) {
                    if (!this.croppedImage) {
                        return jSith.when(this.imageUri);
                    }
                    return jSith.when(this.croppedImage);
                } else {
                    return jSith.when(this.imageUri);
                }
            } else {
                return jSith.when(); //empty promise bc no image has been uploaded and no imageUri was passed in initially
            }
        };

        this.model.getImageFileName = () => {
            if (this.uploadedFile) {
                return this.uploadedFile.name;
            }
        };

        this.model.getImageType = () => {
            let fileName = this.model.getImageFileName();
            return fileName.substring(fileName.lastIndexOf(".") + 1);
        };

        //expose ability for creator to get width
        this.model.getWidth = () => {
            if (this.hasCropper) {
                return this.croppedImageWidth;
            }
        };

        this.model.getErrorMsg = () => {
            return this.errorMsg;
        };

        this.modelChange.emit(this.model);
    }

    rotate(direction: number) {
        this.rotation += direction;
        this.cd.detectChanges();
    }

    scale(scaleStep: number) {
        if (this.transform.scale + scaleStep < 1) {
            return;
        }
        this.transform.scale += scaleStep;
        this.transform = { ...this.transform, scale: this.transform.scale };
        this.cd.detectChanges();
    }

    getBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });
    }

    setImageBase64(base64ImgStr: string) {
        //'png'| 'jpg'| 'jpeg'| 'gif'){
        this.imageBase64String = base64ImgStr;
        setTimeout(() => {
            //Set time out to ensure image is set BEFORE detecting changes - was an issue with configure theme
            this.uploadedImageChange.emit(this.imageBase64String);
            this.cd.detectChanges();
        }, 500);
    }

    fileChangeEvent(files: any): void {
        this.errorMsg = "";
        if (files && files[0]) {
            let file = files[0];

            let sizeMb = file.size / 1024 / 1024;
            let fileType = "UNSUPPORTED";
            let fileTypeLoc = ("" + file.name).lastIndexOf(".");
            if (fileTypeLoc > -1) {
                fileType = ("" + file.name).substring(fileTypeLoc + 1).toLowerCase();
            }

            if (this.SUPPORTED_TYPES.indexOf(fileType) < 0) {
                this.errorMsg =
                    "The file type '" + fileType + "' is not supported. Please upload a png, jpg, jpeg, or gif file.";
            } else if (sizeMb > 5.0) {
                this.errorMsg = "Please upload a file with a size of less than 5MB.";
            } else {
                this.uploadedFile = file;
                this.getBase64(file).then((data) => {
                    this.imageUri = data;
                });
            }
        } else {
            this.errorMsg =
                "Your browser does not support the FileReader API. Please use a modern browser for this functionality.";
        }
        if (this.errorMsg) {
            this.isModal ? this.uploadedImageChange.emit(this.imageBase64String) : alert(this.errorMsg);
        }
        this.cd.detectChanges();
    }

    imageCropped(event: ImageCroppedEvent) {
        if (event) {
            this.croppedImage = event.base64;
            this.croppedImageWidth = event.width;
            this.cd.detectChanges();
        }
    }

    imageLoaded(event: any) {
        this.cd.detectChanges();
        // show cropper
    }

    cropperReady(event: any) {
        // cropper ready: set initial crop
        this.cropperChild.cropper.x1 = 0;
        this.cropperChild.cropper.y1 = 0;
        this.cropperChild.cropper.x2 = Math.min(event.width, this.maxWidth || event.width);
        this.cropperChild.cropper.y2 = Math.min(event.height, this.maxHeight || event.height);
        this.imageCropped(this.cropperChild.crop());
        this.cd.detectChanges();
    }

    loadImageFailed(event: any) {
        this.cd.detectChanges();
        // show message
    }

    onImageUriChange = () => {
        this.setImageBase64(this.imageUri);
    };
}
