//@author: devin

import { NgModule } from "@angular/core";
import { S25ItemComponent } from "./s25.item.component";
import { CommonModule } from "@angular/common";
import { S25ItemEventComponent } from "./item-object/s25.item.event.component";
import { S25ItemOrganizationComponent } from "./item-object/s25.item.organization.component";
import { S25ItemContactComponent } from "./item-object/s25.item.contact.component";
import { S25ItemSpaceComponent } from "./item-object/s25.item.space.component";
import { S25ItemResourceComponent } from "./item-object/s25.item.resource.component";
import { PopoverModule } from "../s25-popover/popover.module";
import { S25ImageModule } from "../s25-image/s25.image.module";
import { S25ItemGenericComponent } from "./item-object/s25.item.generic.component";
import { S25PipesModule } from "../../pipes/s25.pipes.module";
import { S25ModalModule } from "../s25-modal/s25.modal.module";
import { ContextMenuModule } from "../s25-context-menu/context.menu.module";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    declarations: [
        S25ItemComponent,
        S25ItemEventComponent,
        S25ItemSpaceComponent,
        S25ItemResourceComponent,
        S25ItemOrganizationComponent,
        S25ItemContactComponent,
        S25ItemGenericComponent,
    ],
    imports: [
        CommonModule,
        PopoverModule,
        S25ImageModule,
        S25PipesModule,
        S25ModalModule,
        ContextMenuModule,
        NgbPopover,
    ],
    exports: [
        S25ItemEventComponent,
        S25ItemSpaceComponent,
        S25ItemResourceComponent,
        S25ItemOrganizationComponent,
        S25ItemContactComponent,
        S25ItemGenericComponent,
        S25ItemComponent,
    ],
    providers: [
        S25ItemComponent,
        S25ItemEventComponent,
        S25ItemSpaceComponent,
        S25ItemResourceComponent,
        S25ItemOrganizationComponent,
        S25ItemContactComponent,
        S25ItemGenericComponent,
    ],
})
export class S25ItemModule {
    constructor() {}
}
