import { Api } from "../../api/api";
import { CompileService } from "../../main/compile.service";
import { S25LoadingInlineComponent } from "./s25.loading.inline.component";

export class S25LoadingApi extends Api {
    public static init(parentElm: HTMLElement) {
        Api.callApiFn(parentElm, "s25-loading-inline", "init");
    }

    public static destroy(parentElm: HTMLElement) {
        Api.callApiFn(parentElm, "s25-loading-inline", "destroy");
    }

    public static openOnBody(id: string, msg: string) {
        CompileService.getInstance().compileHTML("<s25-inline-loading></s25-inline-loading>", null, {
            id: id,
            text: msg,
        });
    }

    public static closeOnBody(id: string) {
        Api.callApiFn(
            document.body,
            "s25-loading-inline",
            null,
            null,
            (comp: S25LoadingInlineComponent) => {
                if (comp && comp.model && comp.model.id === id) {
                    comp.destroy(true);
                }
            },
            (comp) => {
                return comp && comp.model && comp.model.id === id;
            },
        );
    }
}
