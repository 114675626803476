//@author: mandy
import { Cache } from "../../decorators/cache.decorator";
import { S25Util } from "../../util/s25-util";
import { DataAccess } from "../../dataaccess/data.access";
import { jSith } from "../../util/jquery-replacement";
import { Timeout } from "../../decorators/timeout.decorator";

export class S25ImageService {
    public static BootstrapDefault: any = {
        point: "sm",
        numCols: 3,
    };

    @Cache({ targetName: "S25ImageService" })
    public static getUrl(imageId: number, width?: string | number) {
        return (
            DataAccess.injectCaller(
                DataAccess.getUrl(
                    "/image?image_id=" + imageId + (width ? DataAccess.composeParam("width", width) : ""),
                ),
                "S25ImageService.getUrl",
            ) +
            "&" +
            new Date().getTime()
        );
    }

    public static getUrlExternal(imageId: number) {
        return `${DataAccess.getUrl("/image?image_id=" + imageId)}&fileName=`;
    }

    @Cache({ targetName: "S25ImageService" })
    public static getUrlBootstrapWidth(imageId: any, bootstrap: any) {
        return S25ImageService.getUrl(imageId, S25ImageService._getBootstrapWidth(bootstrap));
    }

    @Timeout
    @Cache({ targetName: "S25ImageService" })
    public static getLoaded(imageId: number, width: any) {
        return jSith.when({ isLoaded: false }); // set by s25-image onLoad()
    }

    @Cache({ targetName: "S25ImageService" })
    public static getLoadedBootstrapWidth(imageId: number, bootstrap: any) {
        return S25ImageService.getLoaded(imageId, S25ImageService._getBootstrapWidth(bootstrap));
    }

    public static _getBootstrapWidth(bootstrap: any) {
        return S25Util.bootstrap.getColMaxWidth(
            (bootstrap && bootstrap.point) || S25ImageService.BootstrapDefault.point,
            (bootstrap && bootstrap.numCols) || S25ImageService.BootstrapDefault.numCols,
            bootstrap && bootstrap.maxWidth,
        );
    }
}
