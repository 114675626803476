//@author: devin
import { jSith } from "../../util/jquery-replacement";
import { S25Util } from "../../util/s25-util";

export class InfiniteScrollUtil {
    public static getScrollPosn(topElement: HTMLElement | Window) {
        return topElement?.scrollY || topElement?.scrollTop || 0;
    }

    public static getScrollHeight(topElement: HTMLElement | Window) {
        if (topElement === window) return jSith.height(document);
        return (topElement && S25Util.coalesce(topElement.scrollHeight, jSith.height(document))) || 0;
    }

    public static getTopElementHeight(topElement: HTMLElement | Window) {
        return (topElement && jSith.height(topElement)) || 0;
    }

    public static invisiblePixelHeight(topElement: HTMLElement | Window) {
        return InfiniteScrollUtil.getScrollHeight(topElement) - InfiniteScrollUtil.getTopElementHeight(topElement);
    }

    public static percentScrolled(topElement: HTMLElement) {
        return (
            (100 * InfiniteScrollUtil.getScrollPosn(topElement)) / InfiniteScrollUtil.invisiblePixelHeight(topElement)
        );
    }

    public static hasScrollbar(topElement: HTMLElement) {
        return InfiniteScrollUtil.invisiblePixelHeight(topElement) !== 0;
    }
}
